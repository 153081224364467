<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :key="resetTab" :list="statusTab" :filter.sync="filter" :status="basicStatusTab" title="Documentos emitidos" :breadcrumbs="breadcrumbs" :hiddenTab="checkPermissionDTE" :scroll="scroll">
      <template v-slot:main v-if="!checkPermissionDTE">
        <v-btn v-if="enabledCreateDTE && $store.state.auth.account.country !== 'PE'" color="blue-500" :to="{ name: 'DocumentsCreate' }" :ripple="false"><v-icon left>mdi-file-plus</v-icon>Crear documento</v-btn>
      </template>
      <template v-slot:secondary>
        <template v-if="!checkPermissionDTE">
          <VMainColumnRecordVisualization :headers.sync="headersAux" :pageSize.sync="pageSize" object="documents" />
          <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition" :key="`-${reset}`">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" outlined v-bind="attrs" v-on="on" :disabled="!count">
                <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
                <v-list-item class="px-3" @click="dialogExport=true" :disabled="disabledDownload || !count" :ripple="false">
                  <v-list-item-title class="body-2">Descargar documentos</v-list-item-title>
                </v-list-item>
              <v-tooltip color="white-0" right max-width="185px" transition="scale-transition" open-delay="1000">
                <template v-slot:activator="{ on }">
                  <span class="grey-300--text body-1" v-on="!selectedDocuments.length ? on : false">
                    <v-list-item class="px-3" @click="sendEmailDialog=true" :ripple="false" :disabled="!selectedDocuments.length">
                      <v-list-item-title class="body-2">Enviar documentos</v-list-item-title>
                    </v-list-item>
                  </span>
                </template>
                <span class="d-block px-3 py-2">Debe seleccionar documentos antes de seleccionar una opción</span>
              </v-tooltip>
            </v-list>
          </v-menu>
          <v-query-builder class="ml-2" :query-map="filters" model="documents" :active.sync="activeFilter" @updateTab="setTab()" />
        </template>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0 mt-25">
      <documents-list
        :show-create="true"
        @activeBtnFilter="activeFilter = true"
        :selectedDocuments.sync="selectedDocuments"
        :toggleDropdown.sync="toggleDropdown"
        :headerList="headerList"
        :pageSize="pageSize"
      />
    </v-col>
    <!-- dialog export -->
    <v-dialog v-model="dialogExport" width="700" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-300--text font-weight-semibold">Descarga de documentos emitidos</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogExport=false" icon small retain-focus-on-click><v-icon color="grey-300" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-2">
          <v-list class="pa-0 transparent">
            <!-- <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title class="grey-300--text body-1 font-weight-bold">Resumen de la exportación</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item class="mt-2">
              <v-list-item-content class="pt-0">
                <v-list-item-title class="grey-300--text body-1 font-weight-semibold">Selecciona el formato de los archivos:</v-list-item-title>
                <v-list-item-subtitle class="pt-2">
                  <v-row class="ml-n1" no-gutters>
                    <v-col cols="6" class="pa-0" v-for="file in fileFormat" :key="file">
                      <v-checkbox class="ma-0 vcheckbox" @click="getFileFormat(file)" :input-value="types.includes(file) || false" column :label="file" :disabled="['XLSX', 'CSV', 'XML'].includes(file) && count >= 1000000" dense hide-details />
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- alert -->
          <v-alert class="blue-500--text rounded-md mt-2 inf-alert-blue" :height="58" dense text>
            <div class="d-flex pa-0 my-1">
              <v-icon color="blue-500" size="22">mdi-information-outline</v-icon>
              <div class="ml-4">
                <span class="body-2" style="line-height: 14px;">Esta acción
                  <span class="font-weight-bold">podría tardar varios minutos</span> en completarse. Recibirás un
                  <span class="font-weight-bold"> correo electrónico con el enlace de descarga.</span>
                </span>
              </div>
            </div>
          </v-alert>
          <!-- end alert -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogExport=false" :ripple="false">Cancelar</v-btn>
          <v-btn color="blue-500" @click="createExport" :ripple="false" :disabled="!types.length">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog export -->

    <!-- email -->
    <v-dialog v-model="sendEmailDialog" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-300--text font-weight-semibold">Enviar documento por email</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="sendEmailDialog=false" icon small retain-focus-on-click><v-icon color="grey-300" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-col class="pa-0">
            <v-card class="transparent rounded-0 pa-0" flat>
              <v-card-subtitle class="background pt-5 pb-3 px-5">
                <div class="d-flex align-center">
                  <span class="grey-300--text body-1" style="min-width: 60px;">Para</span>
                  <v-combobox class="a-combobox" v-model="messages.recipient" hide-details :items="sendEmailList" item-value="id" item-text="name" chips small-chips multiple outlined required hide-selected hide-no-data single-line dense autocomplete="off">
                    <template v-slot:selection="data">
                      <v-chip class="pr-2" small v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">{{ data.item.name }}</v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center mt-3">
                  <span class="grey-300--text body-1" style="min-width: 60px;">Asunto</span>
                  <v-text-field v-model="messages.subject" outlined required single-line dense maxlength="60" persistent-hint  hide-details />
                </div>
                <v-col class="pa-0 mt-2">
                  <span class="grey-300--text body-1 mr-5">Archivos adjuntos</span>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="pdf" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>*.pdf
                  </v-chip>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="xml" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>*.xml
                  </v-chip>
                </v-col>
              </v-card-subtitle>
              <v-divider />
              <v-card-text class="pa-0 pb-4 mx-auto bgPanel overflow-y-auto text-center" style="max-height: 450px;">
                <v-alert class="inf-alert blue-500--text" :height="50" dense text outlined color="#BED2F0" tile>
                  <div class="d-flex px-2 py-0">
                    <v-icon color="blue-500" size="26">mdi-information</v-icon>
                    <div class="ml-4 py-1">
                      <span class="body-1">Esta es una vista previa del documento N° {{ (selectedDocuments[0] || []).number }}.</span>
                    </div>
                  </div>
                </v-alert>
                <v-alert border="top" class="pa-0 mt-6 mx-auto" color="var(--light-blue-500)" colored-border elevation="0" :style="`width: ${$vuetify.breakpoint.width < 1270 ? 465 : 564}px;`" />
                  <v-sheet class="mx-auto" :width="$vuetify.breakpoint.width < 1270 ? 467 : 566" style="border-radius: 10px; margin-top: -17px;" outlined>
                    <reminder-documents-email v-if="selectedDocuments" :instance="selectedDocuments[0]" :activeDocument="activeDocument((selectedDocuments[0] || []).document_type || {})" :message.sync="messages.text" />
                  </v-sheet>
              </v-card-text>
              <v-divider />
              <v-card-actions class="py-4 px-5">
                <v-spacer />
                <v-btn @click="sendEmailDialog=false" outlined>Cancelar</v-btn>
                <v-btn @click="createMail" :loading="$store.state.messages.createLoader" color="blue-500">Enviar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end email -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { groupBy, isEqual } from 'lodash'
import MiniHeader from '@/components/commons/MiniHeader'
import { documentsFilters } from '@/collections/queryBuilderFilters'
import DocumentTypes from '@/collections/documentTypes'
import documentStatuses from '@/collections/documentStatuses'
import DocumentsList from '@/modules/documents/components/DocumentsList'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import ListViewMixin from '@/mixins/ListViewMixin'
import VMainColumnRecordVisualization from '@/components/commons/VMainColumnRecordVisualization'
import ReminderDocumentsEmail from '@/components/reminders/documents/Email'
import numeral from 'numeral'
import moment from 'moment'

export default {
  components: {
    VMainColumnRecordVisualization,
    DocumentsList,
    VQueryBuilder,
    MiniHeader,
    ReminderDocumentsEmail
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    resetTab: 0,
    errorXML: null,
    errorCSV: null,
    pageSize: null,
    headerList: [],
    activeFilter: false,
    reset: 0,
    toggleDropdown: null,
    sendEmailList: [
      {
        name: 'Email de emisión (intercambio)',
        id: 'taxpayer'
      },
      {
        name: 'Email de contactos',
        id: 'customer'
      }
    ],
    selectedDocuments: [],
    sendEmailDialog: false,
    scroll: 0,
    filter: 0,
    dialogExport: false,
    filters: documentsFilters,
    documentTypes: DocumentTypes,
    documentStatuses,
    loading: false,
    types: [],
    messages: {
      text: '',
      recipient: '',
      subject: '',
      type: ['pdf', 'xml']
    },
    status: [
      {
        title: 'Aceptado',
        icon: 'mdi-check',
        countries: ['CL', 'PE']
      },
      {
        title: 'Procesando',
        icon: 'mdi-sync',
        countries: ['CL', 'PE']
      },
      {
        title: 'Pendiente',
        icon: 'mdi-clock',
        countries: ['PE']
      },
      {
        title: 'Rechazado',
        icon: 'mdi-close',
        countries: ['CL', 'PE']
      },
      {
        title: 'Anulado',
        icon: 'mdi-file-remove',
        countries: ['CL', 'PE']
      }
    ],
    breadcrumbs: {
      main: 'Emisión',
      children: [
        {
          text: 'Documentos'
        }
      ]
    },
    quantitySelectedDocumentTypeForSendEmail: '',
    fileFormat: ['XLSX', 'CSV', 'XML'], // 'PDF'
    headers: [
      { text: 'Cliente', value: 'customer', sortable: true, show: true, disabled: false, label: 'Cliente', fixed: true },
      { text: 'Tipo de documento', value: 'document_type', sortable: true, show: true, disabled: false, label: 'Tipo de documento', fixed: true },
      { text: 'Folio#', value: 'number', align: 'end', sortable: true, show: true, disabled: false, label: 'Folio #', fixed: true },
      { text: 'Fecha del doc.', value: 'date', align: 'end', sortable: true, show: true, disabled: false, label: 'Fecha del documento', fixed: true },
      { text: 'Fecha de crea.', value: 'created', align: 'end', sortable: true, show: false, disabled: false, label: 'Fecha de creación', fixed: false },
      { text: 'Estado', value: 'status', show: true, disabled: false, label: 'Estado', fixed: true },
      { text: 'Total', value: 'total', align: 'end', sortable: true, show: true, disabled: false, label: 'Total', fixed: true },
      { text: '', value: 'actions', align: 'end', width: 30, show: true}
    ],
    headersAux: []
  }),
  computed: {
    ...mapState({
      count: state => state.documents.documentsList.length
    }),
    statusTab () {
      const list = this.documentStatuses?.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))
      return Object.entries(groupBy(list, 'summary')).map(([key, value]) => {
        return { key, count: value.length, children: value }
      })
    },
    basicStatusTab () {
      return this.status.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))
    },
    activeDocument () {
      return (id) => {
        return DocumentTypes.find(d => d.id === id)
      }
    },
    checkPermissionDTE () {
      if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        return !ownGroupPermissions.filter(({permission}) => permission === 'view_document').length
        
      }
      return false
    },
    enabledCreateDTE() {
      if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        return ownGroupPermissions.filter(({permission}) => permission === 'add_document').length > 0
      }
      return true
    },
    disabledDownload () {
      if (this.$route.query?.ordering) return true
      if (this.$route.query?.status__code__in) {
        if (Object.keys(this.$route.query)?.length > 1) {
         return false
        } return true
      }
      return false
    }
  },
  watch: {
    headersAux: {
      handler (val) {
        this.headerList = val.filter(({show}) => show)
      },
      inmediate: true
    },
    filter: {
      handler (val) {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        if (val > 0) {
          delete currentQuery.page
          const list = this.documentStatuses?.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))
          let query = list.filter((item) => item.summary === this.basicStatusTab[val - 1].title).map((item) => item.code).toString()
          this.$router.replace({name: this.$route.name, query: {...currentQuery, status__code__in: query} }).catch(() => {})
        } else {
          delete currentQuery.status__code__in
          this.$router.replace({ name: this.$route.name, query: currentQuery }).catch(() => { })
        }
      },
      inmediate: false
    },
    $route () {
      if (!this.$route.query?.status__code__in) {
          this.filter = 0
          this.resetTab += 1
      } else this.reset += 1
    },
    selectedDocuments (val) {
      this.messages.subject = `Envía ${this.activeDocument(val[0]?.document_type)?.name ?? ''} N° ${val[0]?.number}`
      this.messages.text = `Estimado cliente, ${val[0]?.customer?.name ?? ''},\n\n`
      this.messages.text += `Adjunto ${this.activeDocument(val[0]?.document_type)?.name ?? ''} N° ${val[0]?.number}, `
      this.messages.text += `correspondiente al ${moment(val[0]?.date).format('DD MMM YYYY')} `
      this.messages.text += `por un monto de ${numeral(val[0]?.total).format('0,0')} ${val[0]?.exchange?.currency_from ?? val[0]?.exchange?.currency_to ?? this.activeDocument(val[0]?.document_type)?.currencies[0] ?? ''} \n\nAtentamente ${this.$store.state?.auth?.account?.name}`
    }
  },
  created () {
    if ((typeof this.getList === 'function' && Object.keys(this.$route?.query).length)) {
      this.getList()
    }
    this.setFilterDocumentType()
    this.setFilterStatusType()
    // this.setTab()
    this.filters = this.filters?.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))

    if (this.$route.query.status__code__in) {
      const query = this.$route.query.status__code__in.split(',')[0]
      this.statusTab.forEach((item) => {
        const statusAux = item.children.map((child) => child.code.toString())[0]
        if (query === statusAux) {
          const index = this.status.findIndex(({title}) => title === item.key)
          this.filter = index !== -1 ? index < `${this.$store.state?.auth?.account?.country === 'CL' ? 3 : 5}` ? index + 1 : index : 0
        }
      })
    }
    if (this.$route.query.branch_id) {
      this.breadcrumbs.children.push({
        text1: this.$route.query.name,
        route1: { name: 'BranchRetrieveUpdate', params: { id: this.$route.query.branch_id } }
      })
    }

    if (this.$route.query.posdocument__pos__id) {
      this.breadcrumbs.children.push({
        text1: this.$route.query.name,
        route1: { name: 'PosRetrieveUpdate', params: { id: this.$route.query.branch_id } }
      })
    }

    if (this.$route.query.batchdocument__batch) {
      this.breadcrumbs.children = [
        { text: 'Emisión por lotes', route: { name: 'DocumentsBatchListCreate'} },
        { text1: this.$route.query.batchdocument__batch, route1: { name: 'DocumentsBatchRetrieve', params: { id: this.$route.query.batchdocument__batch }} },
        { text2: 'Documentos'}
      ]
    }

    if (this.$route.query.customer) {
      this.breadcrumbs.children = [
        { text: 'Clientes', route: { name: 'ContactRetrieveUpdate', params: { id: this.$route.query.customer }} },
        { text1: 'Documentos'}
      ]
    }

    this.headersAux = [...this.headers]
  },
  methods: {
    setTab () {
      if (this.$route.query.status__code__in) {
      this.resetTab += 1
        this.statusTab.forEach((item) => {
        const statusList = item.children.map((child) => child.code?.toString())
        const query = this.$route.query.status__code__in.split(',')
        if (isEqual(statusList, query)) {
          const index = this.basicStatusTab.findIndex(({title}) => title === item.key)
          this.filter = index !== -1 ? index + 1 : 0
        }
      })
    }
  },
    setFilterStatusType () {
    const index = this.filters.findIndex(({id}) => id === 'status__code__in')
      this.filters[index].choices = this.statusTab.map((item) => {
      return {
        label: item.key,
        value: item.children.map(({code}) => code).toString()
      }
    })
   },
    getFileFormat (val = null) {
      if (!this.types?.find((s) => s === val)) this.types.push(val)
      else {
        this.types?.splice(this.types?.indexOf(val), 1)
        this.types = [...this.types]
      }
    },
    remove (item) {
      const index = this.messages.recipient.indexOf(item)
      if (index >= 0) {
        this.messages.recipient.splice(index, 1)
      }
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      if (!this.checkPermissionDTE) {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        Object.keys(currentQuery).forEach((key) => {
          if (['branch_id', 'posdocument__pos__id', 'batchdocument__batch'].includes(key)) delete currentQuery.name
        }) 
        if (Object.keys(this.$route.query)?.length) {
          this.$store.dispatch('documents/LIST', {
            resource: 'documents',
            query: { ...currentQuery, ...(!currentQuery?.ordering && { ordering: '-date,-created' }) }
          })
          .finally(() => {
            // this.reset += 1
          })
        }
      }
    },
    setFilterDocumentType () {
      const currentDocumentType = this.documentTypes.filter(({country}) => country === this.$store.getters['base/currentCountry'])
      const index = this.filters.findIndex(({id}) => id === 'document_type__in')
      if (currentDocumentType.length) {
         this.filters[index].choices = currentDocumentType.map(({name, id}) => {
          return {
            label: name,
            value: id
          }
        })

        if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
          /* eslint-disable */
          const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
          this.filters[index].choices = this.filters[index].choices.filter((doc) => ownGroupPermissions.some((item) => item.permission === 'view_document' && item.document_type === doc.value))
        }
      }
    },
    async createExport() {
      this.errorCSV = null
      this.errorXML = null
      this.dialogExport = false
      let array = []
      let currentQuery = this.deleteQueries()
      if (this.types.includes('CSV') && this.types.includes('XLSX')) {
        array = this.types.filter(item => item !== 'CSV' && item !== 'XLSX')
        array.push('XLSX, CSV')
      } else array = this.types
      if (array.includes('XLSX, CSV') || array.find((item) => ['XLSX', 'CSV'].includes(item))) {
        await this.exportCSVandXLSX(currentQuery)
      }
      if (array.includes('XML') || this.$store?.state?.auth?.account?.country === 'PE') {
        this.$store.commit('global/SET_API_SERVICES', false)
        this.$store.dispatch('accounts/LIST', {
          resource: 'exports/documents',
          query: {
            ...currentQuery,
            extension: this.$store?.state?.auth?.account?.country === 'PE' ? this.types.toString() : 'XML',
            ...(((['perPage'].includes(this.toggleDropdown) || (this.selectedDocuments.length > 0 && this.selectedDocuments.length <= 20)) && !['all', 'filtered'].includes(this.toggleDropdown)) && { id__in: this.selectedDocuments.map(({id}) => id).toString()})
          },
          loader: false
        })
        .catch((error) => {
          this.errorXML = this.parseErrors(error.response.data)
        })
        .finally(() => {
          this.types = []
        })
      }
      if (this.errorXML || this.errorCSV) this.$dialog.message.error(`${this.errorXML ? `${this.errorXML} \n` : ''} ${this.errorCSV ? `${this.errorCSV} \n` : ''}}`)
      else this.$dialog.message.info('Se ha iniciado el proceso de descarga y se le notificará via email, una vez que haya finalizado', { timeout: 10000 })
    },
    exportCSVandXLSX (currentQuery = {}) {
      this.$store.commit('global/SET_API_SERVICES', true)
      const query = {
        ...currentQuery,
        ...(((['perPage'].includes(this.toggleDropdown) || (this.selectedDocuments.length > 0 && this.selectedDocuments.length <= 20)) && !['all', 'filtered'].includes(this.toggleDropdown)) && { id__in: this.selectedDocuments.map(({id}) => id).toString()})
      }

      const { formatParams, formatParamsTypeList } = this.setFormatParams(query)
      const formatQuery = formatParams && formatParamsTypeList ? `${formatParams}&${formatParamsTypeList}` : `${!formatParams ? `${formatParamsTypeList}` : `${formatParams}`}`
      this.$store.dispatch('accounts/DOWNLOAD', {
        resource: 'download-center/export-documents',
        payload: {
          query: formatQuery ?? '',
          extension: this.types.filter(item => item !== 'XML').toString(),
        },
        loader: false
      })
      .catch((error) => {
        this.errorCSV = this.parseErrors(error.response.data)
      })
      .finally(() => {
        this.$store.commit('global/SET_API_SERVICES', false)
        this.types = []
      })
    },
    createMail () {
      const type = this.messages.type.length === 2 ? 'all' : this.messages.type.toString()
      let currentQuery = this.deleteQueries()
      this.$store.dispatch('messages/CREATE', {
        resource: 'bulk_messages_filter',
        query: {
          ...((['perPage'].includes(this.toggleDropdown) || (this.selectedDocuments.length <= 20 && !['all', 'filtered'].includes(this.toggleDropdown))) && { id__in: this.selectedDocuments.map(({id}) => id).toString()}),
          ...currentQuery
        },
        payload: {
          ...(this.messages.recipient.find(({id}) => id === 'taxpayer') && {taxpayer: true}),
          ...(this.messages.recipient.find(({id}) => id === 'customer') && {customer: true}),
          ...(type.length && {type})
        }
      })
      .then(() => {
        this.$dialog.message.info('El envío de documentos por email se realizó con éxito')
        this.messages.recipient = ''
        this.messages.type = []
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.sendEmailDialog = false
      })
    },
    deleteQueries () {
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      if (currentQuery.name) delete currentQuery.name
      return currentQuery
    }
  }
}
</script>